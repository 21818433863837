<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
      </div>
    </base-header>

    <div class="container-fluid mt--6">
      <div>
        <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2">
          <template slot="header">
            <h3 class="mb-0">Danh sách api</h3>
          </template>
          <div>
            <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap mb-4"
            >
              <el-select
                class="select-primary pagination-select"
                v-model="listQuery.limit"
                placeholder="Per page"
              >
                <el-option
                  class="select-primary"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </div>
            <el-table :data="tableData"
                      row-key="id"
                      header-row-class-name="thead-light"
                      v-loading="listLoading"
                      @sort-change="sortChange"
                      @selection-change="selectionChange">
              <el-table-column
                v-for="column in tableColumns"
                :key="column.label"
                v-bind="column"
              >
              </el-table-column>

              <el-table-column label="Trạng thái">
                <template slot-scope="scope">
                  <base-switch class="mr-1" v-model="scope.row.is_active" type="success" @input="updateSubmitLinkApi(scope.row)"></base-switch>
                </template>
              </el-table-column>

              <el-table-column min-width="180px" align="right" label="Actions">
                <div slot-scope="{$index, row}" class="d-flex">
                  <base-button
                    @click.native="handleView($index, row)"
                    class="view btn-link"
                    type="info"
                    size="sm"
                    icon
                  >
                    <i class="text-white ni ni-zoom-split-in"></i>
                  </base-button>

                  <base-button
                    @click.native="handleDelete($index, row)"
                    class="remove btn-link"
                    type="danger"
                    size="sm"
                    icon
                  >
                    <i class="text-white ni ni-fat-remove"></i>
                  </base-button>
                </div>
              </el-table-column>
            </el-table>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                Showing {{ listQuery.page }} to {{ listQuery.limit }} of {{ listQuery.total }} entries
              </p>

            </div>
            <base-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="pagination.total"
            >
            </base-pagination>
          </div>
        </card>
      </div>
    </div>

    <modal :show.sync="showDetailModal" size="lg" id="detail-modal">
      <h6 slot="header" class="modal-title">Thông tin API</h6>
      <div class="container-fluid">
        <base-alert type="danger" v-if="errText !== ''">
          <strong>Lỗi!</strong> {{ errText }}
        </base-alert>

        <!-- Form groups used in grid -->
        <div class="row">
          <div class="col-md-12">
            <base-input v-model="api.label" label="Tên" disabled />
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <base-input
              v-model="api.api_endpoint"
              :inputClasses="errors.get('api_endpoint') ? 'is-invalid' : ''"
              label="Api endpoint"
            />
            <div v-if="errors.get('api_endpoint')" class="invalid-feedback" :style="{display: errors.get('api_endpoint') ? 'block' : 'none'}"> {{ errors.get('api_endpoint') }} </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <base-input
              v-model="api.api_key"
              :inputClasses="errors.get('api_key') ? 'is-invalid' : ''"
              label="Api key"
            />
            <div v-if="errors.get('api_key')" class="invalid-feedback" :style="{display: errors.get('api_key') ? 'block' : 'none'}"> {{ errors.get('api_key') }} </div>
          </div>
        </div>

        <base-button type="primary" native-type="submit" @click.prevent="updateSubmitLinkApi(api)" :loading="formLoading">Submit</base-button>
      </div>
    </modal>
  </div>
</template>

<script>
import { Table, TableColumn, Select, Option } from 'element-ui';
import { BasePagination } from '@/components';
import swal from 'sweetalert2';
import Errors from '@/utils/error';


export default {
  components: {
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  data() {
    return {
      tableColumns: [
        {
          prop: 'label',
          label: 'Tên',
          minWidth: 160,
          sortable: true
        },
        {
          prop: 'api_endpoint',
          label: 'Api endpoint',
          minWidth: 220,
          sortable: true
        }
      ],
      tableData: [],
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [10, 25, 50],
        total: 0
      },
      listQuery: {
        page: 1,
        limit: 25,
        search: undefined,
        sortBy: 'created_at',
      },
      listLoading: false,
      showDetailModal: false,
      api: {},
      errors: new Errors(),
      formLoading: false,
      errText: ''
    };
  },
  created() {
    this.getList();
  },
  watch: {
    'listQuery.limit': {
      handler() {
        this.getList();
      },
      deep: true,
    },
  },
  methods: {
    async getList() {
      this.listLoading = true;
      const data = await this.$store.dispatch('links/fetchSubmitLinkApis');

      this.tableData                = data;
      this.pagination.total         = data.length;
      this.pagination.currentPage   = 1;
      this.listQuery.page           = 1;
      this.listLoading              = false;
    },
    async updateSubmitLinkApi(row) {
      let self = this;
      this.formLoading = true;
      this.errors = new Errors();
      this.errText = '';

      try {
        const response = await this.$store.dispatch('links/updateSubmitLinkApis', row);

        this.showDetailModal = false;
        this.$notify({
          message:
            response.status ? 'Cập nhật thông tin api thành công!' : 'Cập nhật thông tin không thành công!',
          timeout: 2000,
          icon: 'ni ni-bell-55',
          type: response.status ? 'success' : 'danger',
          verticalAlign: 'bottom',
          horizontalAlign: 'right'
        });
      } catch (err) {
        if (err.response.status === 422) {
          self.errors.record(err.response.data);
        }
      }
      this.formLoading = false;
    },
    handleDelete(index, row) {
      swal.fire({
        title: 'Are you sure?',
        text: `You won't be able to revert this!`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonClass: 'btn btn-success btn-fill',
        cancelButtonClass: 'btn btn-danger btn-fill',
        confirmButtonText: 'Yes, delete it!',
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          this.deleteRow(row)
            .then(response => {
              swal.fire({
                title: response.status ? 'Thành công!' : 'Lỗi!',
                text: response.message,
                type: response.status ? 'success' : 'error',
                confirmButtonClass: response.status ? 'btn btn-success btn-fill' : 'btn btn-danger btn-fill',
                buttonsStyling: false
              });
            });

          this.getList();
        }
      });
    },
    async deleteRow(row) {
      return await this.$store.dispatch('links/deleteLink', row.id);
    },
    handleView(index, row) {
      this.showDetailModal =true;
      this.api = row;
    },
    selectionChange(selectedRows) {
      this.selectedRows = selectedRows
    },
    sortChange({ prop, order }) {
      if (prop) {
        this.tableData.sort((a, b) => {
          let aVal = a[prop]
          let bVal = b[prop]
          if (order === 'ascending') {
            return aVal > bVal ? 1 : -1
          }
          return bVal - aVal ? 1 : -1
        })
      } else {
        this.tableData.sort((a, b) => {
          return a.id - b.id
        })
      }
    }
  }
};
</script>
<style>
.no-border-card .card-footer{
  border-top: 0;
}
</style>
